import { useFormData } from '../../context/FormState/FormDataContext';
import { FormActionType } from '../../context/FormState/form-state-reducer';
import NextPageIcon from '../../../assets/next-page-navigation-arrow.svg';
import { NavigationButton } from './FooterControls';

function NextPageButton() {
  const { dispatch } = useFormData();
  return (
    <NavigationButton
      onClick={() =>
        dispatch({
          type: FormActionType.INCREMENT_PAGE,
        })
      }
    >
      <img src={NextPageIcon} alt="go to next page"></img>
    </NavigationButton>
  );
}

export default NextPageButton;
