import { DOMAIN, REGION } from '../interfaces';
import axios from './axios';

let domain: DOMAIN = window.location.hostname.split('.').slice(-2).join('.');

export async function getToken(
  slug: string,
  clientId: string,
  clientSecret: string,
  params: {
    grant_type: string;
    client_id: string;
    code: string;
    redirect_uri: string;
  },
) {
  console.log('get token', {
    clientId,
    clientSecret,
    params,
  });
  const response = await axios.post(
    `https://${slug}.auth.us-east-1.amazoncognito.com/oauth2/token?${new URLSearchParams(
      params,
    ).toString()}`,
    null,
    {
      headers: {
        Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  console.log('token response', response.data);
  return response.data.access_token;
}

export function getSigninUrl(
  client_id: string,
  clientSecret: string,
  slug: string,
  region: REGION,
) {
  const params = new URLSearchParams({
    response_type: 'code',
    client_id,
    redirect_uri: `http${domain === 'localhost' ? '' : 's'}://${
      window.location.host
    }/sso/login`,
    state: btoa(
      JSON.stringify({
        client_id,
        clientSecret,
        slug,
        path: window.location.pathname + window.location.search,
      }),
    ),
    scope: 'openid email profile aws.cognito.signin.user.admin',
  });
  console.log('signing url params', Object.fromEntries(params));
  const url = `https://${slug}.auth.${region}.amazoncognito.com/login?${params.toString()}`;
  return url;
}
