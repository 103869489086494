import { Button, createStyles, makeStyles } from '@material-ui/core';
import FirstPageButton from './FirstPageButton';
import PreviousPageButton from './PreviousPageButton';
import NextPageButton from './NextPageButton';
import LastPageButton from './LastPageButton';
import PageSelector from './PageSelector';
import React from 'react';

const FooterStyles = makeStyles(() =>
  createStyles({
    footer: {
      backgroundColor: '#c2c2c2',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: '1em',
    },
  }),
);

function FooterControls() {
  const classes = FooterStyles();
  return (
    <div className={classes.footer}>
      <FirstPageButton />
      <PreviousPageButton />
      <PageSelector />
      <NextPageButton />
      <LastPageButton />
    </div>
  );
}

export function NavigationButton(props: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <Button onClick={props.onClick} className="p-0" variant="text">
      {props.children}
    </Button>
  );
}

export default FooterControls;
