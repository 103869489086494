import Layout from './embed/layout';
import React, { useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getForm, getFormUrlData } from '../lib/formApi';
import { AppContext } from './context/AppState/AppContext';
import { useFormUtils } from './hooks/useFormUtils';
import { trackPromise } from 'react-promise-tracker';
import { getUserPool } from '../lib/ntf';
import { getSigninUrl } from '../lib/cognitoApi';
import { AxiosError } from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import {
  MessageBoxContext,
  MessageBoxStateActions,
  MessageBoxType,
} from '@dispatcher-stratus/stratus-react';
import { useTranslation } from 'react-i18next';
import { FormDataContext } from './context/FormState/FormDataContext';
import { FormActionType } from './context/FormState/form-state-reducer';
import { getTenantByPoolId, getWorkflow } from '../lib/wfxApi';
import { useAppArgs } from './hooks/useAppArgs';
import { useAuthContext } from './context/AuthState/AuthContext';
import { Grid } from '@material-ui/core';
import SubmitButton from './embed/SubmitButton';
import { useFormApi } from './hooks/useFormApi';

type Props = {
  children: React.ReactNode;
};
export const FormDataCollection = (props: Props) => {
  const { state: auth } = useAuthContext();
  const { setAppConfig } = useContext(AppContext);
  const { state: formState, dispatch: formDispatch } =
    useContext(FormDataContext);
  const history = useHistory();
  const { submitExternalForm } = useFormApi();
  const { domain, debug } = useAppArgs();
  const queryClient = useQueryClient();
  const { dispatch: dispatchMessage } = useContext(MessageBoxContext);
  const {
    urlId,
    region,
    slug,
  }: {
    urlId: string;
    region: string;
    slug: string;
  } = useParams();
  const { massageFormInput } = useFormUtils();
  const { t } = useTranslation();

  //MARK: QUERIES
  const { data: userPoolData } = useQuery(
    ['userPool', [slug]],
    async () => {
      const userPool = await trackPromise(getUserPool(slug, domain, region));
      return userPool;
    },
    {
      enabled: !!domain,
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch user pool. Form url may be invalid`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  useQuery(
    ['tenantData', slug],
    async () => {
      const { id, name, plan } = await trackPromise(
        getTenantByPoolId(region, userPoolData.poolId),
      );
      const tenant = { id, name, slug, region, plan };
      setAppConfig({ tenant });
    },
    {
      enabled: !!userPoolData,
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Failed to fetch tenant data.',
          },
        });
      },
    },
  );

  const { data: formUrlData, isLoading } = useQuery(
    ['formUrlData', urlId],
    async () => {
      const formUrlData = await trackPromise(
        getFormUrlData(urlId, auth.token!, region),
      );

      if (formUrlData.workflowId) {
        const workflow = await trackPromise(
          getWorkflow(region, auth.token!, formUrlData.workflowId),
        );
        if (workflow?.status !== 'running') {
          console.log('workflow not running or no access');
          history.push('/unavailable');
        }
      } else {
        console.log('no workflow id');
        history.push('/unavailable');
      }

      if (!formUrlData.formId) {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Node configuration not saved. No form present.',
          },
        });
      } else {
        setAppConfig({
          formId: formUrlData.formId,
          nodeId: formUrlData.nodeId,
          workflowId: formUrlData.workflowId,
          startUrl: formUrlData.url,
        });
      }
      return formUrlData;
    },
    {
      enabled: !!urlId && !!auth.token,
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Failed to fetch form url data.',
          },
        });
      },
    },
  );

  const { data: formData } = useQuery(
    ['formData', formUrlData?.formId],
    async () => {
      const formData = await trackPromise(
        getForm(region, slug, 'stratus.lol', formUrlData?.formId!, auth.token!),
      );
      if (formData.formStatus !== 'published') {
        console.log('form not published');
        history.push('/unavailable');
      }
      const massagedFormData = await massageFormInput(formData, {
        environment: new Map(),
        records: new Map(),
      });
      formDispatch({
        type: FormActionType.POPULATE_FORM,
        payload: massagedFormData,
      });
      return massagedFormData;
    },
    {
      enabled: !!formUrlData?.formId && !!auth.token,
      onSuccess: (data) => {
        document.title = data.title + ' - Dispatcher Stratus';
      },
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch form.`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  //MARK: EFFECTS

  useEffect(() => {
    const state = history.location?.state as { refresh?: boolean };
    if (state?.refresh) {
      setAppConfig({ arn: '', processId: '', returnToken: '', returnUrl: '' });
      formDispatch({
        type: FormActionType.RESET_FORM,
      });
      queryClient.invalidateQueries('formUrlData');
      queryClient.invalidateQueries('formData');
      queryClient.invalidateQueries('workflowStart');
      queryClient.invalidateQueries('workflowInstance');
    }
  }, [history.location, formDispatch, queryClient, setAppConfig]);

  useEffect(() => {
    if (!auth.token && userPoolData) {
      console.log(
        'redirect url data',
        slug,
        region,
        userPoolData.clientId,
        urlId,
      );
      const redirectUrl = getSigninUrl(
        userPoolData.clientId,
        userPoolData.client.ClientSecret,
        slug,
        region,
      );
      console.log('redirect to', redirectUrl);
      window.location.href = redirectUrl;
    }
  }, [auth.token, urlId, userPoolData, region, slug]);
  debug && console.log({ formUrlData, formData, userPoolData });

  if (isLoading) {
    return <></>;
  }

  return (
    <Layout hideFooter hideHeader onSubmit={async () => { }}>
      <Grid container wrap="nowrap" direction="column">
        <Grid item xs>
          {props.children}
        </Grid>
        <Grid item>
          {formState.currentPage.number === formState.numPages && (
            <Grid container justifyContent="center" className="my-10">
              <Grid item className="mx-5">
                <SubmitButton
                  onClick={async () => {
                    console.log('submitting form');
                    await trackPromise(submitExternalForm(formState));
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};
