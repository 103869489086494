import { trackPromise } from 'react-promise-tracker';
import axios from '../../lib/axios';
import { getMetadata } from '@dispatcher-stratus/metadata';
import { FormField } from '../../interfaces/FormFieldTypes';
import { useFormData } from '../context/FormState/FormDataContext';
import { useFormUtils } from './useFormUtils';
import { t } from 'i18next';
import { useAppArgs } from './useAppArgs';
import { useMemo } from 'react';
import { useMetadataApi } from './useMetadataApi';
import { useNTFApi } from './useNTFApi';
import { useAuthContext } from '../context/AuthState/AuthContext';
import { useAppConfig } from '../context/AppState/AppContext';
import { useHistory } from 'react-router-dom';
import { FormDataType } from '../../interfaces';

export const useFormApi = () => {
  const { uploadFormToProcessMetadata } = useMetadataApi();
  const { startWorkflowAndAwaitReady } = useNTFApi();
  const { state: authState } = useAuthContext();
  const { state: appState } = useAppConfig();
  const { state: formState } = useFormData();
  const history = useHistory();
  const { domain, metadata: metadataArg } = useAppArgs();
  const { compileFormData, massageFormInput } = useFormUtils();

  const formApi = useMemo(() => {
    const getForm = async (fid: string) => {
      const response = await axios
        .get(
          `https://${
            metadataArg.tenant.region ?? 'us-east-1'
          }.forms-service.${domain}/api/forms/${fid}`,
          {
            headers: {
              Authorization: 'Bearer ' + metadataArg.token,
              'stc-tenant-slug': metadataArg.tenant.slug,
            },
            withCredentials: false,
          },
        )
        .catch((err) => {
          console.error(err);
          throw new Error(t('error fetchForm'));
        });
      if (response.data.code) {
        console.error(response.data);
        throw new Error(t('error fetchForm'));
      }
      return response.data;
    };

    async function initializeForm(
      rawForm: any,
      sessionID: string,
      workflowID: string,
      userID: string,
      fileID: string,
    ) {
      let metadata = {
        environment: new Map<any, any>(),
        records: new Map<string, any>(),
      };
      metadata = await getMetadata({
        workflowId: workflowID,
        userId: userID,
        processId: sessionID,
        fileId: fileID,
        domain: domain,
        region: metadataArg.tenant.region,
      });

      const massagedForm = await massageFormInput(rawForm, metadata);
      formState.debug &&
        console.log('[DEBUG] massaged form input:', massagedForm);
      return massagedForm;
    }

    async function submitForm(
      formFields: FormField[],
      {
        fileId,
        sessionId,
        region,
        skipMetadataFetch = false,
      }: {
        url: string;
        token: string;
        sessionId: string;
        fileId: string;
        region: string;
        skipMetadataFetch?: boolean;
      },
    ) {
      const formdata = compileFormData(formFields);
      formState.debug && console.log('[DEBUG] compiled form data', formdata);
      let fileMetadataPayload = {
        data: {},
      };
      if (!skipMetadataFetch) {
        const processMetadata = await trackPromise(
          axios.get(
            `https://${
              region ?? 'us-east-1'
            }.metadata-api.${domain}/api/v1/file/${sessionId}/${fileId}`,
          ),
        ).catch((err) => {
          console.error('Error fetching process metadata:', err);
          throw new Error(t('error fetchMetadata'));
        });

        fileMetadataPayload = {
          data: {
            ...processMetadata.data.data,
            form: { ...processMetadata.data.data.form, ...formdata },
          },
        };
      } else {
        fileMetadataPayload.data = {
          form: formdata,
        };
      }
      formState.debug &&
        console.log('[DEBUG] Process metadata payload', fileMetadataPayload);

      const putFileData = await trackPromise(
        axios.put(
          `https://${
            region ?? 'us-east-1'
          }.metadata-api.${domain}/api/v1/file/${sessionId}/${fileId}`,
          fileMetadataPayload,
        ),
      ).catch((err) => {
        console.error('Error uploading process metadata:', err);
        throw new Error(t('error uploadMetadata'));
      });
      formState.debug &&
        console.log('[DEBUG] Process metadata upload response:', putFileData);

      // await sendReport(url, token, '');

      // if (!mock || window.location.hostname !== 'localhost')
      //   window.location.href = `https://mfp.${domain}/processing?arn=${sessionId}`;
    }

    async function sendReport(url: string, token: string, userToken: string) {
      const body = {
        token,
        userToken,
        success: true,
        response: '',
      };
      const response = await trackPromise(axios.post(url, body)).catch(
        (err) => {
          console.error(
            'Failed to send report:',
            err.response.status,
            err.response.data,
            err,
          );
          throw new Error(t('error sendReport'));
        },
      );
      formState.debug && console.log('[DEBUG] Report response:', response.data);
      return response;
    }

    async function uploadMetadata(
      formdata: FormData,
      workflowId: string,
      sessionId: string,
      region: string,
      skipMetadataFetch: boolean,
    ) {
      let processMetadataPayload = {
        data: {},
      };
      if (!skipMetadataFetch) {
        const processMetadata = await trackPromise(
          axios.get(
            `https://${
              region ?? 'us-east-1'
            }.metadata-api.${domain}/api/v1/process/${workflowId}/${sessionId}`,
          ),
        ).catch((err) => {
          console.error('Error fetching process metadata:', err);
          throw new Error(t('error fetchMetadata'));
        });

        processMetadataPayload = {
          data: {
            ...processMetadata.data.data,
            form: { ...processMetadata.data.data.form, ...formdata },
          },
        };
      } else {
        processMetadataPayload.data = {
          form: formdata,
        };
      }
      formState.debug &&
        console.log('[DEBUG] Process metadata payload', processMetadataPayload);

      const putFileData = await trackPromise(
        axios.put(
          `https://${
            region ?? 'us-east-1'
          }.metadata-api.${domain}/api/v1/process/${workflowId}/${sessionId}`,
          processMetadataPayload,
        ),
      ).catch((err) => {
        console.error('Error uploading process metadata:', err);
        throw new Error(t('error uploadMetadata'));
      });
      formState.debug &&
        console.log('[DEBUG] Process metadata upload response:', putFileData);
      return putFileData.data;
    }

    async function submitInternalForm(form: FormDataType) {
      console.log('compiling form data...');
      const formdata = compileFormData(form.formDefinition.fields);
      console.log('starting process...');
      const { arn, callbackUrl, token } = await startWorkflowAndAwaitReady();

      console.log('uploading metadata...');

      const response = await uploadFormToProcessMetadata(
        arn,
        appState.formId,
        formdata,
        {
          internalform: {
            formId: appState.formId,
            formTitle: form.title,
            formUrl: appState.startUrl,
          },
        },
      );
      console.log(
        'metadata uploaded',
        response,
        token?.substring(token.length - 5),
      );

      await sendReport(callbackUrl, token, authState.token!);
      history.push('/success', {
        node: 'jobticket',
        tenant: appState.tenant,
        urlId: appState.urlId,
      });
    }

    async function submitExternalForm(form: FormDataType) {
      console.log('compiling form data...');
      const formdata = compileFormData(form.formDefinition.fields);
      console.log('starting process...');
      const { arn, callbackUrl, token } = await startWorkflowAndAwaitReady();

      console.log('uploading metadata...');

      const response = await uploadFormToProcessMetadata(
        arn,
        appState.formId,
        formdata,
        {
          externalform: {
            formId: appState.formId,
            formTitle: form.title,
            formUrl: appState.startUrl,
          },
        },
      );
      console.log(
        'metadata uploaded',
        response,
        token?.substring(token.length - 5),
      );

      await sendReport(callbackUrl, token, authState.token!);
      history.push('/success', {
        node: 'jobticket',
        tenant: appState.tenant,
        urlId: appState.urlId,
      });
    }

    return {
      initializeForm,
      submitForm,
      getForm,
      uploadMetadata,
      sendReport,
      submitInternalForm,
      submitExternalForm,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState]);
  return formApi;
};
