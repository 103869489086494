import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { AuthContextReducer } from './auth-reducer';
import { AuthRegions, AuthState } from '../../../interfaces/AuthState';
import jwt from 'jsonwebtoken';
import { AuthActionType, AuthAction } from './auth-actions';
import axios from '../../../lib/axios';
import { useAppArgs } from '../../hooks/useAppArgs';
import { getToken } from '../../../lib/cognitoApi';

type AuthContextType = {
  state: AuthState;
  dispatch: (action: AuthAction) => void;
};

export type AuthActionsApi = {
  handleAuth: (
    clientId: string,
    clientSecret: string,
    params: {
      grant_type: string;
      client_id: string;
      code: string;
      redirect_uri: string;
    },
  ) => Promise<void>;
  handleAuthSWS: () => Promise<boolean>;
  setToken: (token: string) => void;
  setTokenError: () => void;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType,
);
export const AuthApiContext = createContext<AuthActionsApi>(
  {} as AuthActionsApi,
);

const getRegionFromToken = (token: string): AuthRegions => {
  const decodedToken = jwt.decode(token) as { iss: string };
  const iss = decodedToken.iss;
  return iss.split('.')[1] as AuthRegions;
};

export const AuthContextProvider = ({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState: AuthState;
}) => {
  const [state, dispatch] = useReducer(AuthContextReducer, initialState);
  const { tenant, fallbackDomain } = useAppArgs();

  const authApi = useMemo(() => {
    const setToken: AuthActionsApi['setToken'] = (token: string) => {
      try {
        const region = getRegionFromToken(token);
        dispatch({
          type: AuthActionType.SET_TOKEN,
          payload: {
            token,
            region,
          },
        });
      } catch (err) {
        console.error(err);
        dispatch({ type: AuthActionType.TOKEN_ERROR });
      }
    };

    const handleAuth: AuthActionsApi['handleAuth'] = async (
      clientId,
      clientSecret,
      params,
    ) => {
      const response = await getToken(
        tenant.slug,
        clientId,
        clientSecret,
        params,
      );
      dispatch({
        type: AuthActionType.SET_TOKEN,
        payload: {
          region: tenant.region,
          token: response.access_token,
        },
      });
    };

    const handleAuthSWS: AuthActionsApi['handleAuthSWS'] = async () => {
      console.log('domain', fallbackDomain);
      const url = `https://${tenant.slug}.tenant.${fallbackDomain}/api/sso/service/${tenant.slug}`;
      try {
        const response = await axios.get(url, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = response.data as Record<string, string>;
        if (data?.token) {
          setToken(data.token);
          return true;
        } else if (data?.error) {
          setTokenError();
          return false;
        }
      } catch (error) {
        console.log(error);
        setTokenError();
        return false;
      }
      return false;
    };

    const setTokenError: AuthActionsApi['setTokenError'] = () => {
      dispatch({ type: AuthActionType.TOKEN_ERROR });
    };

    return { setToken, handleAuth, setTokenError, handleAuthSWS };
  }, [fallbackDomain, tenant]);

  return (
    <AuthApiContext.Provider value={authApi}>
      <AuthContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        {children}
      </AuthContext.Provider>
    </AuthApiContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context)
    throw Error(
      'Auth Context not found. Use this hook with AuthContextProvider.',
    );
  return context;
};

export const useAuthApiContext = () => {
  const context = useContext(AuthApiContext);
  if (!context)
    throw Error(
      'Auth API Context not found. Use this hook with AuthApiContextProvider.',
    );
  return context;
};
