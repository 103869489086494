import axios from './axios';

export async function getFormUrlData(
  urlId: string,
  userToken: string,
  region: string,
): Promise<{
  id: string;
  url: string;
  creator: string;
  editor: string;
  formId: string;
  nodeId: string;
  workflowId: string;
  tenantRegion: string;
  tenantSlug: string;
  updated: number;
  created: number;
}> {
  try {
    const response = await axios.get(
      `https://${region}.forms-service.stratus.lol/api/formurl/${urlId}`,
      {
        headers: {
          Authorization: `Bearer, ${userToken}`,
        },
      },
    );
    console.log('formurl data', response.data);
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error('Failed to fetch form url data');
  }
}

export async function getForm(
  region: string,
  slug: string,
  domain: string,
  formId: string,
  token: string,
) {
  try {
    if (!formId) throw Error('No Form ID');
    const response = await axios.get(
      `https://${region ?? 'us-east-1'
      }.forms-service.${domain}/api/forms/${formId}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'stc-tenant-slug': slug,
        },
        withCredentials: false,
      },
    );
    console.log('form data response', response.data);
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error('failed to fetch form');
  }
}
