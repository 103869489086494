export enum FormFieldTypeEnum {
  number = 'number',
  text = 'text',
  textarea = 'textarea',
  checkbox = 'checkbox',
  pageBreak = 'pagebreak',
  password = 'password',
  dropdown = 'dropdown',
  datetime = 'date',
  hidden = 'hidden',
  label = 'label',
}

type GenericFieldConfig = {
  defaultValue?: any;
  variable: string;
  showHelp?: boolean;
  helpText?: string;
  excludeFromMetadata: boolean;
};

type GenericField = {
  id: string;
  value?: any;
  type: FormFieldTypeEnum;
  config: GenericFieldConfig;
  page: number;
  visible: boolean;
};

export interface TextField extends GenericField {
  type: FormFieldTypeEnum.text;
  value: string;
  visible: boolean;
  config: {
    variable: string;
    title: string;
    defaultValue: string;
    mask: boolean;
    required: boolean;
    readonly: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText: string;
    maxLength: number;
    minLength: number;
  };
}

export interface TextAreaField extends GenericField {
  type: FormFieldTypeEnum.textarea;
  value: string;
  visible: boolean;
  config: {
    variable: string;
    title: string;
    defaultValue: string;
    minLength: number;
    maxLength: number;
    required: boolean;
    readonly: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText: string;
  };
}

export interface NumberField extends GenericField {
  type: FormFieldTypeEnum.number;
  value: string;
  visible: boolean;
  config: {
    variable: string;
    title: string;
    defaultValue: number;
    minValue: number;
    maxValue: number;
    required: boolean;
    readonly: boolean;
    mask: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText: string;
  };
}

export interface CheckboxField extends GenericField {
  type: FormFieldTypeEnum.checkbox;
  value: boolean;
  visible: boolean;
  config: {
    variable: string;
    title: string;
    defaultValue: string;
    trueLabel: string;
    falseLabel: string;
    readonly: boolean;
    checked: boolean;
    buttonsList: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText: string;
  };
}

export interface PasswordField extends GenericField {
  type: FormFieldTypeEnum.password;
  value: string;
  visible: boolean;
  config: {
    variable: string;
    title: string;
    minLength: number;
    maxLength: number;
    required: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText: string;
  };
}
export interface DropDownField extends GenericField {
  type: FormFieldTypeEnum.dropdown;
  value: string[];
  visible: boolean;
  config: {
    variable: string;
    title: string;
    defaultValue: string;
    required: boolean;
    readonly: boolean;
    multiSelect: boolean;
    buttonsList: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText: string;
    options: DropDownOption[];
  };
}

type DropDownOption = {
  id: string;
  label: string;
  value: string;
  show: boolean;
  isDefault: boolean;
};

export interface DateTimeField extends GenericField {
  type: FormFieldTypeEnum.datetime;
  value: string;
  visible: boolean;
  config: {
    variable: string;
    title: string;
    defaultValue: string;
    minValue?: Date;
    maxValue?: Date;
    returnFormat: string;
    defaultToNow: boolean;
    required: boolean;
    readonly: boolean;
    excludeFromMetadata: boolean;
    showHelp: boolean;
    helpText: string;
  };
}

export interface HiddenField extends GenericField {
  id: string;
  visible: boolean;
  value: string;
  type: FormFieldTypeEnum.hidden;
  config: {
    variable: string;
    defaultValue: string;
    excludeFromMetadata: boolean;
  };
}

export interface LabelField extends GenericField {
  id: string;
  type: FormFieldTypeEnum.label;
  visible: boolean;
  config: {
    variable: string;
    title: string;
    excludeFromMetadata: true;
  };
}

export interface PageBreakField extends GenericField {
  id: string;
  visible: boolean;
  type: FormFieldTypeEnum.pageBreak;
  config: {
    variable: string;
    title: string;
    hideBack: boolean;
    hideNext: boolean;
    hideScan: boolean;
    hideHome: boolean;
    hideReturn: boolean;
    hidePageNumbers: boolean;
    showHelp: boolean;
    helpText: string;
    excludeFromMetadata: true;
  };
}

export type FormField =
  | TextField
  | TextAreaField
  | NumberField
  | PasswordField
  | CheckboxField
  | DropDownField
  | DateTimeField
  | HiddenField
  | LabelField
  | PageBreakField;
