import Layout from './embed/layout';
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getForm } from '../lib/formApi';
import { useAppConfig } from './context/AppState/AppContext';
import { useFormUtils } from './hooks/useFormUtils';
import { trackPromise } from 'react-promise-tracker';
import { AxiosError } from 'axios';
import {
  MessageBoxContext,
  MessageBoxStateActions,
  MessageBoxType,
} from '@dispatcher-stratus/stratus-react';
import { useTranslation } from 'react-i18next';
import { useAppArgs } from './hooks/useAppArgs';
import {
  useAuthContext,
  useAuthApiContext,
} from './context/AuthState/AuthContext';
import { useHistory, useParams } from 'react-router-dom';
import { useMetadataApi } from './hooks/useMetadataApi';
import { FormDataContext } from './context/FormState/FormDataContext';
import { FormActionType } from './context/FormState/form-state-reducer';
import { Grid } from '@material-ui/core';
import SaveButton from './embed/SaveButton';
import ResetButton from './embed/ResetButton';
import { getWorkflow } from '../lib/wfxApi';

type Props = {
  children: React.ReactNode;
};
export const FormEdit = (props: Props) => {
  const { state: auth } = useAuthContext();
  const { handleAuthSWS } = useAuthApiContext();
  const { state: appState, setAppConfig } = useAppConfig();
  const { state: formState, dispatch: formDispatch } =
    useContext(FormDataContext);
  const { tenant, fileId } = useAppArgs();
  const { dispatch: dispatchMessage } = useContext(MessageBoxContext);
  const { getProcessMetadata, uploadFormToProcessMetadata, getFileMetadata } =
    useMetadataApi();
  const { massageFormInput, compileFormData } = useFormUtils();
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{
    processId: string;
    formId: string;
    region: string;
    slug: string;
    workflowId: string;
  }>();

  //MARK: QUERIES

  const { data: processMetadata } = useQuery(
    ['processData', params.processId],
    async () => {
      const processData = await trackPromise(
        getProcessMetadata(params.processId),
      );
      return processData;
    },
    {
      enabled:
        !!params.processId && !!appState.workflowId && !!auth.isAuthenticated,
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch form.`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  useQuery(
    ['formData', params.formId],
    async () => {
      const formData = await trackPromise(
        getForm(
          tenant.region,
          tenant.slug,
          'stratus.lol',
          params.formId,
          auth.token!,
        ),
      );

      if (formData.formStatus !== 'published') {
        console.log('form not published');
        history.push('/unavailable');
      }

      if (appState.workflowId) {
        const workflow = await trackPromise(
          getWorkflow(tenant.region, auth.token!, appState.workflowId),
        );
        if (workflow?.status !== 'running') {
          console.log('workflow not running or no access');
          history.push('/unavailable');
        }
      } else {
        console.log('no workflow id');
        history.push('/unavailable');
      }

      return formData;
    },
    {
      enabled: !!params.formId && !!auth.isAuthenticated && !!processMetadata,
      onSuccess: async (formData) => {
        let defaultValueOverrides = {};
        if (fileId) {
          const fileMetadata = await getFileMetadata(fileId);
          defaultValueOverrides = fileMetadata.data.form;
        } else if (processMetadata.data?.forms[params.formId]) {
          defaultValueOverrides = processMetadata.data.forms[params.formId];
        }
        const massagedFormData = await massageFormInput(
          formData,
          {
            environment: new Map(),
            records: new Map(),
          },
          defaultValueOverrides,
        );

        console.log({ massagedFormData });
        formDispatch({
          type: FormActionType.POPULATE_FORM,
          payload: massagedFormData,
        });
      },
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch form.`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  //MARK: EFFECTS
  useEffect(() => {
    if (!auth.isAuthenticated) {
      const success = handleAuthSWS();
      if (!success) {
        history.push(`/unauthrized`);
      }
    }
    setAppConfig({
      formId: params.formId,
      processId: params.processId,
      workflowId: params.workflowId,
      tenant: {
        id: '',
        slug: params.slug,
        region: params.region,
        plan: '',
        name: '',
      },
    });
  }, [history, params, auth.isAuthenticated, handleAuthSWS, setAppConfig]);

  return (
    <>
      <Layout
        hideHeader
        hideFooter
        onSubmit={async () => {
          const compiledFormFields = await compileFormData(
            formState.formDefinition.fields,
          );
          const response = await uploadFormToProcessMetadata(
            params.processId,
            params.formId,
            compiledFormFields,
            {},
          );
          console.log(response);
        }}
      >
        <Grid container wrap="nowrap" direction="column" spacing={0}>
          <Grid item xs>
            {props.children}
          </Grid>
          {formState.currentPage.number === formState.numPages && (
            <Grid container justifyContent="center" className="my-10">
              <Grid item className="mx-5">
                <ResetButton />
              </Grid>
              <Grid item className="mx-5">
                <SaveButton />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Layout>
    </>
  );
};
