import { DOMAIN, REGION } from '../interfaces';
import axios from './axios';

const domain: DOMAIN = window.location.hostname.split('.').slice(-2).join('.');
function getUrl(region: REGION) {
  return `https://${region}.wfx.${domain === 'localhost' ? 'stratus.lol' : domain}`;
}

export async function getWorkflow(region: REGION, userToken: string, workflowId: string) {
  const ignoreCache = Date.now();
  const response = await axios.get(`${getUrl(region)}/api/wfx/${workflowId}.json?ignoreCache=${ignoreCache}`,
    {
      headers: {
        Authorization: `bearer ${userToken}`
      },
    },).catch(error => {
      console.log(error);
      return { data: undefined };
    })
  console.log()
  return response.data;
}

export async function getTenantByPoolId(region: REGION, poolId: string) {
  const response = await axios.get(`${getUrl(region)}/api/tenants/${poolId}`);
  console.log('tenant data', response.data);
  return response.data;
}
