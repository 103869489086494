import Layout from './layout';
import { FormWrapper } from '../Form';
import { useContext, useEffect, useRef } from 'react';
import { useProcessData } from '../hooks/useProcessData';
import { useFormData } from '../context/FormState/FormDataContext';
import { useAppArgs } from '../hooks/useAppArgs';
import {
  MessageBoxContext,
  MessageBoxStateActions,
  MessageBoxType,
} from '@dispatcher-stratus/stratus-react';
import { t } from 'i18next';
import {
  useAuthApiContext,
  useAuthContext,
} from '../context/AuthState/AuthContext';
import { useFormApi } from '../hooks/useFormApi';
import { useAppConfig } from '../context/AppState/AppContext';
import { useFormUtils } from '../hooks/useFormUtils';
import { useMetadataApi } from '../hooks/useMetadataApi';

export const Mfp = () => {
  const { isLoading, data } = useProcessData();
  const queryParams = useAppArgs();
  const { uploadFormToFileMetadata, uploadFormSubmissionToFileMetadata } =
    useMetadataApi();
  const { state: formState } = useFormData();
  const { sendReport } = useFormApi();
  const { compileFormData } = useFormUtils();
  const { state: appState, setAppConfig } = useAppConfig();
  const { setToken } = useAuthApiContext();
  const { state: authState } = useAuthContext();
  const { dispatch } = useContext(MessageBoxContext);
  const valid = useRef(validateNodeConfig());

  function validateNodeConfig() {
    const { processId, workflowId, nodeID, metadata, returnURL, returnToken } =
      queryParams;
    return (
      !!processId &&
      !!workflowId &&
      !!nodeID &&
      !!metadata.tenant.region &&
      !!metadata.tenant.slug &&
      !!metadata.userId &&
      !!metadata.token &&
      !!returnURL &&
      !!returnToken
    );
  }

  if (queryParams.debug) {
    console.log('[DEBUG] Node Config:', queryParams);
    console.log('[DEBUG] Process Data:', { isLoading, data });
  }

  useEffect(() => {
    if (!authState.isAuthenticated) {
      setToken(queryParams.metadata.token);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.metadata.token, authState.isAuthenticated]);

  useEffect(() => {
    if (!valid.current) {
      dispatch({
        type: MessageBoxStateActions.MESSAGE_BOX,
        payload: {
          open: true,
          boxType: MessageBoxType.None,
          title: t('error invalidConfig title'),
          message: t('error invalidConfig body'),
        },
      });
    } else {
      setAppConfig({
        processId: queryParams.processId,
        workflowId: queryParams.workflowId,
        tenant: queryParams.metadata.tenant,
        returnUrl: queryParams.returnURL,
        returnToken: queryParams.returnToken,
        nodeId: queryParams.nodeID,
        debug: queryParams.debug,
      });
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid.current]);
  return (
    <Layout
      onSubmit={async () => {
        console.log('compiling form data...');
        const formdata = compileFormData(formState.formDefinition.fields);
        console.log('formdata', formdata);
        const fileResponse = await uploadFormToFileMetadata(
          data.fileID,
          formState.id,
          formdata,
        );
        console.log('metadata uploaded', fileResponse);

        const processReponse = await uploadFormSubmissionToFileMetadata(
          data.fileID,
          formState.id,
        );
        console.log('metadata uploaded', processReponse);

        await sendReport(
          appState.returnUrl,
          appState.returnToken,
          authState.token!,
        );
        window.location.href = `https://mfp.${queryParams.fallbackDomain}/processing?arn=${appState.processId}`;
      }}
    >
      {!isLoading && !!data.fileID && !!data.formID && valid.current && (
        <FormWrapper formID={data.formID} />
      )}
    </Layout>
  );
};
