import Layout from './embed/layout';
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getForm, getFormUrlData } from '../lib/formApi';
import { useAppConfig } from './context/AppState/AppContext';
import { useFormUtils } from './hooks/useFormUtils';
import { trackPromise } from 'react-promise-tracker';
import { AxiosError } from 'axios';
import {
  MessageBoxContext,
  MessageBoxStateActions,
  MessageBoxType,
} from '@dispatcher-stratus/stratus-react';
import { useTranslation } from 'react-i18next';
import { FormDataContext } from './context/FormState/FormDataContext';
import { FormActionType } from './context/FormState/form-state-reducer';
import { useAppArgs } from './hooks/useAppArgs';
import {
  useAuthContext,
  useAuthApiContext,
} from './context/AuthState/AuthContext';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import SubmitButton from './embed/SubmitButton';
import { useNTFApi } from './hooks/useNTFApi';
import { getTenantByPoolId, getWorkflow } from '../lib/wfxApi';
import { useFormApi } from './hooks/useFormApi';

type Props = {
  children: React.ReactNode;
};
export const JobTicketForm = (props: Props) => {
  const { state: auth } = useAuthContext();
  const { handleAuthSWS } = useAuthApiContext();
  const { setAppConfig } = useAppConfig();
  const { state: formState, dispatch: formDispatch } =
    useContext(FormDataContext);
  const { submitInternalForm } = useFormApi();
  const { urlId, tenant, domain } = useAppArgs();
  const { dispatch: dispatchMessage } = useContext(MessageBoxContext);
  const { massageFormInput } = useFormUtils();
  const { getUserPool } = useNTFApi();
  const { t } = useTranslation();
  const history = useHistory();

  //MARK: QUERIES

  const { data: userPoolData } = useQuery(
    ['userPool', [tenant.slug]],
    async () => {
      const userPool = await trackPromise(getUserPool());
      return userPool;
    },
    {
      enabled: !!domain,
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch user pool. Form url may be invalid`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  useQuery(
    ['tenantData', tenant.slug],
    async () => {
      const { id, name, plan } = await trackPromise(
        getTenantByPoolId(tenant.region, userPoolData.poolId),
      );
      const tenantdata = {
        id,
        name,
        slug: tenant.slug,
        region: tenant.region,
        plan,
      };
      setAppConfig({ tenant: tenantdata });
    },
    {
      enabled: !!userPoolData,
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Failed to fetch tenant data.',
          },
        });
      },
    },
  );

  const { data: formUrlData, isLoading } = useQuery(
    ['formUrlData', urlId],
    async () => {
      const formUrlData = await trackPromise(
        getFormUrlData(urlId, auth.token!, tenant.region),
      );

      if (formUrlData.workflowId) {
        const workflow = await trackPromise(
          getWorkflow(tenant.region, auth.token!, formUrlData.workflowId),
        );
        if (workflow?.status !== 'running') {
          console.log('workflow not running or no access');
          history.push('/unavailable');
        }
      } else {
        console.log('no workflow id');
        history.push('/unavailable');
      }

      if (!formUrlData.formId) {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Node configuration not saved. No form present.',
          },
        });
      } else {
        setAppConfig({
          formId: formUrlData.formId,
          nodeId: formUrlData.nodeId,
          workflowId: formUrlData.workflowId,
          startUrl: formUrlData.url,
        });
      }
      return formUrlData;
    },
    {
      enabled: !!auth.isAuthenticated,
      onError: (err) => {
        console.error(err);
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: 'Failed to fetch form url data.',
          },
        });
      },
    },
  );

  useQuery(
    ['formData', formUrlData?.formId],
    async () => {
      const formData = await trackPromise(
        getForm(
          tenant.region,
          tenant.slug,
          'stratus.lol',
          formUrlData?.formId!,
          auth.token!,
        ),
      );
      if (formData.formStatus !== 'published') {
        console.log('form not published');
        history.push('/unavailable');
      }
      const massagedFormData = await massageFormInput(formData, {
        environment: new Map(),
        records: new Map(),
      });
      formDispatch({
        type: FormActionType.POPULATE_FORM,
        payload: massagedFormData,
      });
      return massagedFormData;
    },
    {
      enabled: !!formUrlData?.formId && !!auth.isAuthenticated,
      onError: (err: AxiosError) => {
        dispatchMessage({
          type: MessageBoxStateActions.MESSAGE_BOX,
          payload: {
            open: true,
            boxType: MessageBoxType.Ok,
            title: t('error formError'),
            message: `Failed to fetch form.`,
          },
        });
        console.error(err?.response?.data);
      },
    },
  );

  //MARK: EFFECTS

  useEffect(() => {
    if (!auth.isAuthenticated) {
      const success = handleAuthSWS();
      if (!success) {
        history.push(`/unauthrized`);
      }
    }
  }, [history, auth.isAuthenticated, handleAuthSWS]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Layout hideHeader hideFooter onSubmit={async () => {}}>
      <Grid container wrap="nowrap" direction="column">
        <Grid item xs>
          {props.children}
        </Grid>
        <Grid item>
          {formState.currentPage.number === formState.numPages && (
            <Grid container justifyContent="center" className="my-10">
              <Grid item className="mx-5">
                <SubmitButton
                  onClick={async () => {
                    console.log('submitting form');
                    await trackPromise(submitInternalForm(formState));
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};
