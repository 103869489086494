import { useFormData } from '../../context/FormState/FormDataContext';
import { FormActionType } from '../../context/FormState/form-state-reducer';
import FirstPageIcon from '../../../assets/first-page-navigation-arrow.svg';
import { NavigationButton } from './FooterControls';

function FirstPageButton() {
  const { dispatch } = useFormData();
  return (
    <NavigationButton
      onClick={() =>
        dispatch({
          type: FormActionType.SET_PAGE,
          payload: 1,
        })
      }
    >
      <img src={FirstPageIcon} alt="go to first page"></img>
    </NavigationButton>
  );
}

export default FirstPageButton;
