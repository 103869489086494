import { useFormData } from '../../context/FormState/FormDataContext';
import { FormActionType } from '../../context/FormState/form-state-reducer';
import LastPageIcon from '../../../assets/last-page-navigation-arrow.svg';
import { NavigationButton } from './FooterControls';

function LastPageButton() {
  const { state: formState, dispatch } = useFormData();
  return (
    <NavigationButton
      onClick={() =>
        dispatch({
          type: FormActionType.SET_PAGE,
          payload: formState.numPages,
        })
      }
    >
      <img src={LastPageIcon} alt="go to last page" />
    </NavigationButton>
  );
}

export default LastPageButton;
